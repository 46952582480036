import { URLS } from '@router'
import { get } from 'lodash-es'

/**
 * 静态路由
 * 根据pathname生成的page_view
 */
export const pageDataLayerConfig = {
  // 个人中心首页
  [URLS.profile]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'homepage',
    'page_type': 'detail'
  },
  // 个人中心通知列表
  [URLS.profileNotification]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'notifications',
    'page_type': 'detail'
  },
  // 个人中心保存房源列表
  [URLS.profileLists]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'saved properties',
    'page_type': 'detail'
  },
  // 个人中心浏览记录
  [URLS.profileHistory]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'view history',
    'page_type': 'detail'
  },
  // 个人中心收藏搜索
  [URLS.profileSavedSearch]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'saved searches',
    'page_type': 'detail'
  },
  // 个人中心看房行程
  [URLS.profileJourney]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Open home planner',
    'page_type': 'detail'
  },
  // 个人中心邮件咨询
  [URLS.profileEnquiry]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Email enquiries',
    'page_type': 'detail'
  },
  // 个人中心我认领的房源
  [URLS.profileTrackedProperties]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Tracked places',
    'page_type': 'detail'
  },
  // 个人中心我认领的城区
  [URLS.profileTrackedSuburb]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Tracked suburbs',
    'page_type': 'detail'
  },
  // 个人中心订阅设置
  [URLS.profileCommunications]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Communications',
    'page_type': 'detail'
  },
  // 个人中心设置密码
  [URLS.profilePassword]: {
    'event': 'page_view',
    'vertical': 'profile',
    'vertical_type': 'Change password',
    'page_type': 'detail'
  },
  // 隐私政策
  [URLS.privacyPolicy]: {
    'event': 'page_view',
    'vertical': 'oneroof',
    'vertical_type': 'privacy policy',
    'page_type': 'homepage'
  },
  // 条款和条件
  [URLS.disclaimer]: {
    'event': 'page_view',
    'vertical': 'oneroof',
    'vertical_type': 'disclaimer and disclosure',
    'page_type': 'homepage'
  },
  // 服务条款
  [URLS.userTerms]: {
    'event': 'page_view',
    'vertical': 'oneroof',
    'vertical_type': 'user terms',
    'page_type': 'homepage'
  },
  // 常见问题
  [URLS.faq]: {
    'event': 'page_view',
    'vertical': 'oneroof',
    'vertical_type': 'faq',
    'page_type': 'homepage'
  }
}

export function getPageDataLayer(pathName) {

  return get(pageDataLayerConfig, pathName)
}
