'use client'

import { clearDataLayer, pushDataLayer, pushDataLayerByPathName } from '@plugin/google/gtm'
import { getToken, getUUID } from '@plugin/login'
import { usePathname } from 'next/navigation'
import { useEffect, useState } from 'react'
import { useMount } from 'react-use'

export default function PageDataLayer() {

  const pathName = usePathname()
  const [fired, setFired] = useState(false)
  useEffect(() => {

    if (!fired) {
      setFired(true)
      const uuid = getUUID()
      const token = getToken()

      pushDataLayer({
        'user_id': uuid
      })
      pushDataLayer({
        'is_logged_in': !!token
      })
      return
    }
    pushDataLayerByPathName(pathName)
  }, [pathName, setFired, fired])
}

export function PageViewLayer({
  data,
  value,
  clear = false
}) {

  useMount(() => {
    if (clear) clearDataLayer()
    pushDataLayer(data, value)
  })
}