import { getUUID, getToken } from '@plugin/login'
import { assign } from 'lodash-es'
import { getPageDataLayer } from './config'
import { replaceObjectVariable } from '@utils'

const NEXT_PAGE_DATA_LAYER_KEY = 'or-next-page-data-layer'

/**
 * 根据pathname push数据
 * @param {string} pathName 
 * @param {object} value
 */
export function pushDataLayerByPathName(pathName, value) {
  const data = getPageDataLayer(pathName)
  if (!data) return
  pushDataLayer(data, value)
}

export function pushNextPageDataLayer(data) {
  if (data) {
    let tem = assign({}, getNextPageDataLayer(), data)
    localStorage.setItem(NEXT_PAGE_DATA_LAYER_KEY, JSON.stringify(tem))
  }
}

export function getNextPageDataLayer() {
  return JSON.parse(localStorage.getItem(NEXT_PAGE_DATA_LAYER_KEY)) || null
}

export function removeNextPageDataLayer() {
  localStorage.removeItem(NEXT_PAGE_DATA_LAYER_KEY)
}

export function pushDataLayer(data, value) {
  if (!data) return

  try {
    data = replaceObjectVariable(data, value)
    const dataLayer = window.dataLayer || []
    if (data.event === 'page_view') {
      const uuid = getUUID()
      const token = getToken()
      data = assign({ user_id: uuid, is_logged_in: !!token }, data, getNextPageDataLayer())
      removeNextPageDataLayer()
    }

    dataLayer.push(data)
  } catch (error) { }
}

export function clearDataLayer() {
  if (window.dataLayer instanceof Array) window.dataLayer.splice(0, window.dataLayer.length)
  let gtm = window.google_tag_manager ? window.google_tag_manager['GTM-58D24DV'] : null
  if (gtm && gtm.dataLayer) gtm.dataLayer.reset()
}